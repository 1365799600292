import { MeiliSearch } from 'meilisearch'

export function useMeilisearch() {
  const {
    host,
    apiKey,
  } = useMailearchConfig()

  return new MeiliSearch({
    host,
    apiKey,
  })
}
