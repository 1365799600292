export function useMailearchConfig() {
  // HOTFIX: useRuntimeConfig is not available in the context of the vue setup function. But it is available in the context of the nuxt context.
  // TODO: Remove this hotfix after the upgrade to the latest version of nuxt.
  const config = useRuntimeConfig()

  return {
    host: config.app.MEILISEARCH_HOST,
    apiKey: config.app.MEILISEARCH_KEY,
  }
}
